import { Icon } from "@iconify/react"

export const SocialLinks = (t: any) => {
    return (
        <>
            <a href={process.env.NEXT_PUBLIC_INSTAGRAM_PAGE} target="_blank" rel="noreferrer" className="text-black" aria-label={t("common.visit_instagram")}>
                <Icon icon="mdi:instagram" />
            </a>
            <a href={process.env.NEXT_PUBLIC_TIKTOK_PAGE} target="_blank" rel="noreferrer" className="text-black" aria-label={t("common.visit_tiktok")}>
                <Icon icon="ic:baseline-tiktok" />
            </a>
        </>
    )
}