import { useTranslation } from "next-i18next"
import { customBlockRenderer } from "../shared/customBlockRenderer"
import { pageTitle, sectionTitle } from "./misc/titles"
import Image from "next/image"
import { SocialLinks } from "./misc/SocialLinks"

export default function Introduction({ authorData, loading }: { authorData: any, loading: boolean }) {
    const { t } = useTranslation()

    const loadingAnimation = () => {
        return (
            <div className="relative bg-white p-4 rounded animate-pulse max-w-[80rem] w-full">
                <div className="flex flex-col lg:flex-row lg:justify-between gap-5 w-full">
                    <div className="flex flex-col justify-start text-left lg:w-2/3 gap-2">
                        <div className="h-[3rem] w-full bg-gray-300 dark:text-gray-300 rounded"></div>
                        <div className="h-4 w-2/3 bg-gray-300 dark:text-gray-300 rounded"></div>
                        <div className="h-4 w-4/6 bg-gray-300 dark:text-gray-300 rounded"></div>
                        <div className="h-4 w-5/7 bg-gray-300 dark:text-gray-300 rounded"></div>
                        <div className="h-4 w-2/4 bg-gray-300 dark:text-gray-300 rounded"></div>
                    </div>
                    <div className="h-4 w-full h-[20rem] bg-gray-300 dark:text-gray-300 rounded"></div>
                </div>
            </div>
        )
    }

    return (
        <>
            {loading ? (
                loadingAnimation()
            ) : (
                <div className='relative max-w-[80rem] w-full mx-auto font-poppins'>
                    {pageTitle({title: "Explorer's Tale"})}
                    {sectionTitle({title: t("common.welcome")})}
                    <div className='flex flex-col lg:flex-row lg:justify-between gap-5'>
                        {/* Left Column */}
                        <div className='flex flex-col justify-start text-left lg:w-2/3'>
                        <div className='flex-row'>
                            {authorData && authorData?.authorsinfo?.data?.length > 0 && (
                                <>
                                    <div className='prose max-w-none'>
                                        {customBlockRenderer(authorData?.authorsinfo?.data?.[0]?.attributes?.longDescription)}
                                    </div>
                                    <hr className="border-gray-300 w-full h-[2px] mt-3 mb-3 hidden lg:block" />
                                    <div className="hidden lg:block">
                                        {socialPanel(t)}
                                    </div>
                                </>
                            )}
                        </div>
                        </div>

                        {/* Right Column */}
                        <div className="flex flex-col gap-3 items-center lg:w-1/3">
                            <div className="relative h-[30rem] lg:h-[30rem] w-[20rem]">
                            <Image
                                src="/assets/me.jpg"
                                alt="Alessio Lucciola"
                                fill={true}
                                style={{ objectFit: "cover", borderRadius: '1rem', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
                                sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 33vw"
                                priority={true}
                            />
                            </div>
                            {/* Render socialPanel only on smaller screens */}
                            <div className="block lg:hidden">
                                {socialPanel(t)}
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </>
    )
}

export const socialPanel = (t: any) => {
    return (
        <div className="flex flex-col gap-1 items-center text-center">
            <span>{t("contacts.social")}:</span>
            <div className="flex flex-row gap-5 text-3xl pl-0 text-center md:pl-3 items-center justify-center">
                {SocialLinks(t)}
            </div>
        </div>
    )
}