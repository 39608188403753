//import { home_texts } from '../assets/texts/texts'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
//import { HeaderImage } from '../components/HeaderImage'
import SliderComponent from '../components/ArticleSlider'
import { GET_AUTHOR, GET_LATEST_POSTS, GET_MOST_POPULAR_POSTS } from '../assets/api/apiCalls'
import { getApolloClient } from './_app'
import { GetStaticProps } from 'next'
import { useEffect, useRef, useState } from 'react'
import Introduction from '../components/Introduction'

const FeaturedDestinations = dynamic(() => import('../components/FeaturedDestinations').then(mod => mod.FeaturedDestinations), { ssr: false })
const AdvertisementPosts = dynamic(() => import('../components/AdvertisementPosts').then(mod => mod.AdvertisementPosts), { ssr: false })
const LatestPosts = dynamic(() => import('../components/LatestPosts').then(mod => mod.LatestPosts), { ssr: false })
const MostPopularPosts = dynamic(() => import('../components/MostPopularPosts').then(mod => mod.MostPopularPosts), { ssr: false })

export default function Home({ latestPosts: initialLatestPosts, mostPopularPosts: initialMostPopularPosts, introductionInfo: initialIntroductionInfo }: { latestPosts: any, mostPopularPosts: any, introductionInfo: any }) {
    const router = useRouter()
    const locale = router.locale
    const { t } = useTranslation()
    const client = getApolloClient()

    const [latestPosts, setLatestPosts] = useState(initialLatestPosts)
    const [latestPostsLoading, setLatestPostsLoading] = useState(false)
    const [mostPopularPosts, setMostPopularPosts] = useState(initialMostPopularPosts)
    const [mostPopularPostsLoading, setMostPopularPostsLoading] = useState(false)
    const [introductionInfo, setIntroductionInfo] = useState(initialIntroductionInfo)
    const [introductionInfoLoading, setIntroductionInfoLoading] = useState(false)

    // Fetch posts when locale changes
    const prevLocaleRef = useRef(locale)

    useEffect(() => {
        if (prevLocaleRef.current !== locale) {
            if (latestPosts) {
                fetchLatestPosts()
            }
            
            if (mostPopularPosts) {
                fetchMostPopularPosts()
            }

            if (introductionInfo) {
                fetchIntroductionInfo()
            }
            
            // Update the previous locale
            prevLocaleRef.current = locale
        }
    }, [locale])

    const fetchLatestPosts = async () => {
        console.log('Fetching latest posts')
        try {
            setLatestPostsLoading(true)

            const { data, loading } = await client.query({
                query: GET_LATEST_POSTS,
                variables: {
                    "locale": locale,
                    "limit": 5
                }
            })

            setLatestPosts(data)
            setLatestPostsLoading(loading)
        } catch (error) {
            console.error('Error fetching posts:', error)
            setLatestPosts(null)
            setLatestPostsLoading(false)
        }
    }

    const fetchMostPopularPosts = async () => {
        try {
            setMostPopularPostsLoading(true)

            const { data, loading } = await client.query({
                query: GET_MOST_POPULAR_POSTS,
                variables: {
                    "locale": locale,
                    "limit": 5
                }
            })

            setMostPopularPosts(data)
            setMostPopularPostsLoading(loading)
        } catch (error) {
            console.error('Error fetching posts:', error)
            setMostPopularPosts(null)
            setMostPopularPostsLoading(false)
        }
    }

    const fetchIntroductionInfo = async () => {
        try {
            setIntroductionInfoLoading(true)

            const { data, loading } = await client.query({
                query: GET_AUTHOR,
                variables: {
                    "locale": locale,
                    "name": "Alessio",
                    "surname": "Lucciola"
                }
            })

            setIntroductionInfo(data)
            setIntroductionInfoLoading(false)
        } catch (error) {
            console.error('Error fetching introduction info:', error)
            setIntroductionInfo(null)
        }
    }

    const getPageInOtherLanguages = (locale: string) => {
        if (locale === 'en') {
            return (
                <>
                    <link rel="alternate" href="https://explorerstale.com/" hrefLang="it" />
                </>
            )
        } else {
            return (
                <>
                    <link rel="alternate" href="https://explorerstale.com/en" hrefLang="en" />
                </>
            )
        }
    }

    const getMetadata = () => {
        return (
            <Head>
                <title>Explorer's Tale</title>
                <meta name="description" content={t("meta.index_description")} />
                <meta property="og:title" content="Explorer's Tale" />
                <meta property="og:image" content={`https://explorerstale.com/assets/logo.jpg`} />
                <meta property="og:description" content={t("meta.index_description")} />
                <meta property="og:url" content={locale !== "it" ? `https://explorerstale.com/${locale}/` : `https://explorerstale.com/`} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content={locale} />
                <meta property="og:site_name" content="Explorer's Tale" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@explorerstale" />
                <meta name="twitter:creator" content="@explorerstale" />
                <meta name="twitter:title" content="Explorer's Tale" />
                <meta name="twitter:description" content={t("meta.index_description")} />
                <meta name="twitter:image" content={`https://explorerstale.com/assets/logo.jpg`} />
                <link rel="canonical" href={locale !== "it" ? `https://explorerstale.com/${locale}/` : `https://explorerstale.com/`} />
                <meta name="author" content="Alessio Lucciola" />
                <meta name="robots" content="all" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="content-language" content={locale} />
                <meta name="keywords" content={t("meta.index_keywords")} />
                <link rel="alternate" href="https://explorerstale.com" hrefLang="x-default" />
                {getPageInOtherLanguages(locale)}
            </Head>
        )
    }

    return (
        <>
            {getMetadata()}
            {/*<HeaderImage image={`/assets/hero.jpg`} alt="hero" texts={home_texts}/>*/}
            <SliderComponent data={latestPosts}/>
            <div className='relative max-w-[80rem] mx-auto mt-10 font-poppins'>
                <div className='flex flex-col mx-auto mr-5 ml-5 justify-center gap-3'>
                    <Introduction authorData={introductionInfo} loading={introductionInfoLoading}/>
                    <FeaturedDestinations />
                    <AdvertisementPosts />
                    <LatestPosts latestPostsData={latestPosts} latestPostsLoading={latestPostsLoading} />
                    <MostPopularPosts mostPopularPostsData={mostPopularPosts} mostPopularPostsLoading={mostPopularPostsLoading}/>
                </div>
            </div>
        </>
    )
}

export const getStaticProps: GetStaticProps = async (context) => {
    const client = getApolloClient()
    const locale = context.locale
    
    try {
        let { data: latestPosts } = await client.query({
            query: GET_LATEST_POSTS,
            variables: {
                "locale": locale,
                "limit": 5
            },
            fetchPolicy: 'network-only'
        })

        let { data: mostPopularPosts } = await client.query({
            query: GET_MOST_POPULAR_POSTS,
            variables: {
                "locale": locale,
                "limit": 5
            },
            fetchPolicy: 'network-only'
        })

        let { data: introductionInfo } = await client.query({
            query: GET_AUTHOR,
            variables: {
                "locale": locale,
                "name": "Alessio",
                "surname": "Lucciola"
            }
        })

        return {
            props: {
                ...(await serverSideTranslations(locale, ['common', 'navbar', 'post'])),
                latestPosts,
                mostPopularPosts,
                introductionInfo
            }
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
    
        // Return empty props or an error message
        return {
            props: {
                ...(await serverSideTranslations(locale, ['common', 'navbar', 'post'])),
                LatestPosts: null,
                mostPopularPosts: null,
                introductionInfo: null,
                error: 'Failed to fetch post data',
            },
        }
    }
}