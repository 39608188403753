interface titleProps {
    title: string
}

export const sectionTitle = ({title}: titleProps) => {
    return (
        <div>
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold tracking-tight text-gray-700 font-extrabold font-poppins uppercase">{title}</h2>
            <hr className="w-20 h-1 bg-primary mt-2 mb-2 rounded-full" />
        </div>
    )
}

export const pageTitle = ({title}: titleProps) => {
    return (
        <h1 id="title" className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-800 font-extrabold font-poppins uppercase">{title}</h1>
    )
}

export const categoryTitle = ({title}: titleProps) => {
    return (
        <div>
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-tight text-gray-800 font-extrabold font-poppins uppercase">{title}</h2>
            <hr className="w-20 h-1 bg-primary mt-2 mb-2 rounded-full" />
        </div>
    )
}