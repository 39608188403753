import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
//import Slider from 'react-slick'
import Image from 'next/image'
//import "slick-carousel/slick/slick.css"
//import "slick-carousel/slick/slick-theme.css"
import { getImageFromStrapiUploads } from '../shared/utils'
import Link from 'next/link'

const SliderComponent = ({data}: {data: any}) => {
    //const [showPageNumbers, setShowPageNumbers] = useState(false)
    //const [activeSlide, setActiveSlide] = useState(0)
    const [articleToShow, setArticleToShow] = useState(null)
    const { t } = useTranslation()

    {/*
        useEffect(() => {
            const handleResize = () => {
                setShowPageNumbers(window.innerWidth > 512)
            }

            handleResize()

            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }, [])
    */}

    useEffect(() => {
        if (data) {
            const articleIndex = Math.random() * (data?.posts?.data.length - 1)
            setArticleToShow(data?.posts?.data[Math.floor(articleIndex)])
        }
        
    }, [data])

    const getRandomNumber = (min: number, max: number) => {
        return Math.random() * (max - min) + min
    }

    {/*
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            appendDots: (dots: any) => (
                <div>
                    <ul className='absolute bottom-[3rem] left-1/2 transform -translate-x-1/2'>
                        <div className='flex flex-row items-center justify-between gap-2'>
                            {dots && dots.map((dot: any) => (
                                <div
                                    key={dot.key}
                                    className={`w-6 h-6 border rounded-full inline-flex cursor-pointer ${
                                        activeSlide.toString() === dot.key.toString()
                                            ? "bg-white text-black"
                                            : "text-white"
                                    }`}
                                >
                                    {dot}
                                </div>
                            ))}
                        </div>
                    </ul>
                </div>
            ),
            customPaging: (page: any) => (
                <div className={`flex flex-row inline-flex items-center justify-center`}>
                    {page+1}
                </div>
            ),
            beforeChange: (current: number, next: number) => {
                setActiveSlide(next)
            }
        }


        return (
            <div className='relative max-w-[80rem] w-full overflow-hidden font-poppins -mb-[1rem]'>
                <div className='slick-carousel text-left'>
                <Slider {...settings}>
                        {data?.posts?.data.map((post: any) => (
                            <div key={post.id} className="relative h-[60vh] lg:h-[60vh] w-full">
                                <Image
                                    src={post?.attributes?.postCover?.data !== null ? getImageFromStrapiUploads(post?.attributes?.postCover?.data?.attributes) : "/assets/no-img.jpg"}
                                    alt={post?.attributes?.slug+`-cover`}
                                    layout="fill"
                                    objectFit="cover"
                                    objectPosition="center"
                                    priority={true}
                                    className="xl-m:rounded-xl xl-m:shadow-lg bg-primary bg-opacity-50"
                                    quality={50}
                                    sizes="(max-width: 768px) 100vw, 50vw"
                                />
                                <div className="absolute inset-0 bg-black bg-opacity-30 xl-m:rounded-xl"></div>
                                <div className="absolute bottom-10 left-0 p-5 text-white m-1">
                                    <p className='uppercase'>{t("post.featured")}</p>
                                    <p className="text-2xl md:text-4xl lg:text-6xl font-medium tracking-tight font-extrabold font-poppins">{post?.attributes?.postTitle}</p>
                                    <hr className="w-[20%] bg-white mt-5 mb-2 rounded-full" />
                                    <Link href={{ pathname: "/post/[slug]", query: { slug: post?.attributes?.slug }}} className="hover:no-underline text-white">
                                        {t("post.read_more") + " >"}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        )
    */}

    return (
        <div className='relative max-w-[80rem] w-full mx-auto overflow-hidden font-poppins -mb-[1rem]'>
            <div className="relative h-[60vh] lg:h-[60vh] w-full">
                <Image
                    src={articleToShow?.attributes?.postCover?.data !== null ? getImageFromStrapiUploads(articleToShow?.attributes?.postCover?.data?.attributes) : "/assets/no-img.jpg"}
                    alt={articleToShow?.attributes?.slug+`-cover`}
                    fill
                    priority={true}
                    className="xl-m:rounded-xl xl-m:shadow-lg bg-primary bg-opacity-50 object-cover object-center"
                    quality={50}
                    sizes="(max-width: 480px) 100vw, 
                        (max-width: 768px) 100vw, 
                        (max-width: 1024px) 75vw, 
                        (max-width: 1280px) 50vw, 
                        50vw"
                />
                <div className="absolute inset-0 bg-black bg-opacity-30 xl-m:rounded-xl"></div>
                <div className="absolute bottom-5 left-0 p-5 text-white m-1">
                    <p className='uppercase'>{t("post.featured")}</p>
                    <p className="text-2xl md:text-4xl lg:text-6xl font-medium tracking-tight font-extrabold font-poppins">{articleToShow?.attributes?.postTitle}</p>
                    <hr className="w-[20%] bg-white mt-5 mb-2 rounded-full" />
                    <Link href={{ pathname: "/post/[slug]", query: { slug: articleToShow?.attributes?.slug }}} className="hover:no-underline text-white">
                        {t("post.read_more") + " >"}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SliderComponent